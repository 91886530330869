import React from 'react';
import './Footer.css'; // Import the Footer CSS
import { Link } from 'react-router-dom';  // Import Link to navigate between pages

function Footer() {
  return (
    <footer className="App-footer">
      {/* Left section: Contact Information */}
      <div className="contact-info">
        <h2>Contact Us</h2>
        <p><strong>Email:</strong> contact@itsfranky.com</p>
        <p><strong>Phone:</strong> (+56) 1234 56789</p>
        <p><strong>Location:</strong> Santiago, Chile</p>
      </div>

      {/* Right section: Links to Pages */}
      <div className="links">
        <Link to="/">Home</Link>
        <Link to="/wwu">Work with us</Link>
        <Link to="/AboutUs">About Us</Link>
      </div>
    </footer>
  );
}

export default Footer;
