// App.js
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Wwu from './pages/wwu';
import AboutUs from './pages/AboutUs';
import Footer from './Components/Footer';  // Import Footer component
import Header from './Components/Header';
import Dropdown from './Components/Dropdown'; // Import Dropdown component

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        {/* Use Dropdown component here */}
        <Dropdown />

        {/* Define Routes for Each Page */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wwu" element={<Wwu />} />
          <Route path="/AboutUs" element={<AboutUs />} />
        </Routes>

        {/* Footer - Add at the end of every page */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
