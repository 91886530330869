// Components/Dropdown.js
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import './Dropdown.css';

function Dropdown() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <nav className="dropdown-nav">
      <button onClick={toggleDropdown} className="dropdown-toggle">
        Menu
      </button>
      {isDropdownOpen && (
        <ul ref={dropdownRef} className="dropdown-menu">
          <li>
            <Link to="/" onClick={closeDropdown}>Home</Link>
          </li>
          <li>
            <Link to="/wwu" onClick={closeDropdown}>Work with us</Link>
          </li>
          <li>
            <Link to="/AboutUs" onClick={closeDropdown}>About Us</Link>
          </li>
        </ul>
      )}
    </nav>
  );
}

export default Dropdown;
