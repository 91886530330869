// src/pages/Home.js
import React from 'react';
import './Home.css';

function Home() {
  return (
    <div>
      <section id="about" className="App-section">
        <div className="text-image-container">
          <div className="text-content">
            <h2 className="home-heading">What is Franky?</h2>
            <p>
              Franky is a location-based app that connects your store with nearby customers, helping you engage them without the need for delivery or having an individual app. With us, users can explore the catalog of your store, discover promotions, and skip buying lines—all in one place.
            </p>
          </div>
          <img src="/Franky-app.png" alt="Franky Manager" className="franky-image" />
        </div>

          <div className="second-text-content">
            <h2 className="home-heading">Flash Offers:</h2>
            <p>
              We propose this new method of interacting with your customers. Flash offers are a feature that will allow you to present offers unique to Franky´s users that are nearby your store. These offers can be either limited by a fixed amount of the product or by time, and both can be adapted to the needs of your store. Users will be able to access these offers by being nearby the store but also by having a set amount of loyalty points.
            </p>
            <p>
              These loyalty points will be granted every time a customer buys a product from your store through Franky. This way, you can ensure that you are rewarding your most loyal customers.
            </p>
        </div>  
      </section>
    </div>
  );
}

export default Home;
