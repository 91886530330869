// src/components/Header.js
import React from 'react';
import './Header.css'; // Add any header-specific styles if needed

function Header() {
  return (
    <header className="App-header">
      <img src="/Franky_Logo.jpg" alt="Franky Logo" className="App-logo" />
      <h1>Franky</h1>
      <p>The App That Simplifies Store-Customer Interaction</p>
    </header>
  );
}

export default Header;
