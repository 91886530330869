// pages/wwu(Work with us)
import React from 'react';
import './wwu.css';

function wwu() {
  return (
    <div>
      <div className="App-section">
        <h2 className="wwu-heading">What is Franky?</h2>
        <ul>
          <li><strong>Seamless In-Place Interaction:</strong> Engage customers when they are near or inside your store with exclusive promotions, digital loyalty rewards, and real-time offers.</li>
          <li><strong>Reduced Costs:</strong> No hefty commissions like delivery apps. A low-cost, scalable platform to promote your business.</li>
          <li><strong>Control Over Engagement:</strong> Stay in control of how you interact with customers through customized promotions and loyalty programs.</li>
          <li><strong>Powerful Data Insights:</strong> Gain actionable insights into customer foot traffic and behavior.</li>
        </ul>
      </div>
    </div>  
  );
}

export default wwu;
